import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import { Helmet } from "react-helmet"

const PressReleases = ({ data }) => {
  return (
    <Layout>
      <Helmet>
      <title>Press Releases</title>
    </Helmet>
      <section class="hero is-halfheight hero-banner" id="events-hero">
            <div class="hero-body">
            </div>
      </section>      
      <main>
          <h1>Press Releases</h1>
          <div class="read-more-cards">

          <div class="read-more-card">
              <div class="card-title">The KEEP Foundation and the Race For Education Announce 2022 Scholarship Recipients</div>
                  <div class="card-date">Lexington, Ky. Tuesday, July 5, 2022</div>
                  <div class="card-blurb">
                    <p>The Kentucky Equine Education Project (KEEP) Foundation, and The Race For Education (RFE) are proud to announce their 2022 scholarship recipients.</p>
                    <p>The KEEP Foundation’s board of directors approved over $30,000 in scholarships to 13 students attending nine universities across the Commonwealth. All of the 2022 scholarship recipients are students currently enrolled at a university or college in Kentucky in either an equine and/or agriculture related major or their parents work in the equine industry.</p>
                  </div>
                  <div class="card-cta"><a href="https://myemail.constantcontact.com/The-KEEP-Foundation-and-the-Race-For-Education-Announce-2022-Scholarship-Recipients.html?soid=1103123456303&aid=HMTbqsie56M" target="blank" rel="noopener">Read More →</a></div>
            </div>

            <div class="read-more-card">
              <div class="card-title">The KEEP Foundation Launches Youth Grant Program</div>
              <div class="card-date">Lexington, Ky. Wednesday, May 25, 2022</div>
              <div class="card-blurb">
                <p>The Kentucky Equine Education Project (KEEP) Foundation has launched its first annual youth grant program. The KEEP Foundation is committed to helping support youth who want to become involved with the equine industry for the first time, or continue their involvement in the equine industry.</p>
                <p>The KEEP Foundation Youth Grant is available to individuals ages 21 and under who want to attend an equine educational opportunity in Kentucky, who have an interest and desire to be involved with the industry, but lack the financial support.</p>
              </div>
              <div class="card-cta"><a rel="noreferrer" href="https://myemail.constantcontact.com/The-KEEP-Foundation-Launches-Youth-Grant-Program.html?soid=1103123456303&aid=Pbqpr7KYaBw" target="blank">Read More →</a></div>
            </div>

            <div class="read-more-card">
              <div class="card-title">The KEEP Foundation's Seattle Slew Specialty License Plate Marks Five Years on Kentucky Roads, Benefitting Education and Workforce Development Initiatives</div>
              <div class="card-date">Lexington, Ky. Monday, May 9, 2022</div>
              <div class="card-blurb">
                <p>The KEEP Foundation is celebrating the fifth anniversary of its Seattle Slew specialty license plate. The specialty plate is available through Kentucky's local County Clerks' offices. </p>
                <p>Following the release of the specialty plate on May 1, 2017, the number of Kentuckians choosing to use the Seattle Slew plate have increased annually. With the purchase and renewal of the specialty plate, ten dollars is donated to the KEEP Foundation and is used for educational initiatives related to Kentucky's horse industry. Funding donated to the KEEP Foundation from the license plate has totaled $139,121.</p>
              </div>
              <div class="card-cta"><a rel="noreferrer" href="https://myemail.constantcontact.com/Seattle-Slew-License-Plate-Marks-5-Years-on-Kentucky-Roads.html?soid=1103123456303&aid=rNpzl0GhwR4" target="_blank">Read More →</a></div>
            </div>
          
            <div class="read-more-card">
              <div class="card-title">The KEEP Foundation Hires Alexandra Harper as Executive Director</div>
              <div class="card-date">Lexington, Ky. Monday, January 17, 2022</div>
              <div class="card-blurb">
                <p>The Kentucky Equine Education Project (KEEP) Foundation has hired Alexandra Harper as the organization's new Executive Director.</p>
                <p>Reflecting on the impactful work of the KEEP Foundation in its Equine Workforce Initiative and an investment in broader education about the industry to more young Kentuckians, the KEEP Foundation Board of Directors have hired Alexandra Harper to build on these successes and further elevate the work of the organization across the Commonwealth.</p> 
              </div>
              <div class="card-cta"><a rel="noreferrer" href="https://drive.google.com/file/d/1oqoWoTXuUsF5ZcNHBWs_PhdmQcbm0860/view" target="_blank">Read More →</a></div>
            </div>
          </div>
      </main>
    </Layout>
  )
}

export default PressReleases

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title,
        description,
        siteUrl
      }
    }
  }
`